<template>
  <app-button
    v-bind="$attrs"
    :block="block"
    :color="color"
    :size="size"
    :to="
      contactPageEnabled ? { name: 'contact', query: { origin } } : undefined
    "
    :variant="variant"
    @click="onClick"
  >
    <slot> {{ $t("growth.contact_button.default_label") }} </slot>
  </app-button>

  <lazy-growth-call-scheduling-dialog
    v-if="showDialog"
    v-model="showDialog"
    :origin="origin"
  />
</template>

<script lang="ts" setup>
import type { ButtonProps } from "@pollen/ui-library/client/shared/button/button.model";

import type { CallRequestOrigin } from "~/business-areas/analytics/analytics.model";

defineOptions({
  inheritAttrs: false,
});

defineProps<
  ButtonProps & {
    origin: CallRequestOrigin;
  }
>();

const [showDialog, toggleDialog] = useToggle();

const contactPageEnabled = useFeatureFlag("contact_page");

function onClick() {
  if (contactPageEnabled) return;

  return toggleDialog(true);
}
</script>
